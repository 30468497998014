import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { doc, DocumentReference, getFirestore } from '@firebase/firestore';
import { useFirestore } from '@vueuse/firebase';
import { useAsync } from 'src/composables/async';
import { GlobalData } from 'src/types/firebase';

interface Option {
  value: string;
  label: string;
}

export type AppStore = ReturnType<typeof useAppStore>;
export const useAppStore = defineStore('app', () => {
  const { awaitNotNull } = useAsync();

  const chainId = useLocalStorage('app-chain', '');
  const networkId = useLocalStorage('app-network', '');
  const walletId = useLocalStorage('app-wallet', '');

  const documentRef = ref<DocumentReference<GlobalData>>();
  const document = useFirestore(documentRef);

  const chains = computed(() => {
    const chains = document.value?.chains;
    if (!chains) {
      return [];
    }
    const entries = Object.entries(chains);
    return entries
      .filter(([, chain]) => !chain.locked)
      .map(([id, chain]) => ({ value: id, label: chain.name } as Option));
  });

  const chain = computed(() => {
    if (!chainId.value || !document.value?.chains?.[chainId.value]) {
      return undefined;
    }
    return document.value.chains[chainId.value];
  });

  const networks = computed(() => {
    if (!chain.value?.networks) {
      return [];
    }
    const networks = chain.value.networks;
    const entries = Object.entries(networks);
    return entries.map(
      ([id, chain]) => ({ value: id, label: chain.name } as Option)
    );
  });

  const network = computed(() => {
    if (!networkId.value || !chain.value?.networks) {
      return undefined;
    }
    return chain.value?.networks[networkId.value];
  });

  async function fetch(this: AppStore) {
    const db = getFirestore(this.fbApp);
    documentRef.value = doc(db, '_CONFIG', 'GLOBAL') as never;

    await awaitNotNull(document);
    if (!chainId.value) {
      chainId.value = chains.value[0].value;
    }
    if (!networkId.value) {
      networkId.value = networks.value[0].value;
    }
  }

  return {
    chainId,
    chain,
    chains,
    networkId,
    network,
    networks,
    walletId,
    fetch,
  };
});
