// This is just an example,
// so you can safely delete all default props below

export default {
  layout: {
    title: 'Battle Hard',
    studio: 'Studio',
    contract: 'Contracts',
    admin: 'Admin',
    chain: 'Chain',
    network: 'Network',
    refresh: 'Refresh inventory',
    link: 'Link wallet',
    policy: 'Privacy Policy',
    terms: 'Terms of Service',
    contact: 'Contact us',
  },
  landpage: {
    welcome: {
      call: 'GET BATTLE READY',
      title: 'NFT Battle Infusions',
      description: {
        line1:
          'A Pokemon inspired Trait and Nature system. Take any supported NFT and add generated stats from out elements and natures list.',
        line2: 'What to be supported? Opt-in your contract to get started.',
      },
      action: 'Coming Soon',
      info: {
        tokens: 'Tokens',
        contracts: 'Contracts',
        hardebed: 'Battle hardened',
      },
    },
    features: {
      title: 'Features',
      description:
        'A universal tool to revive old projects and invigorate new and existing',
      universal: {
        title: 'Universal Template',
        description:
          'We went above and beyond to make sure each element and nature was diverse enough to cover a wide variety of needs without controlling how your system works.',
      },
      dynamic: {
        title: 'Dynamic Rehydration',
        description:
          'Should any major updates or fixes need to occur on your project through the config portal, the Infused stats are procedurally calculated on all mint and update operations.',
      },
      overlay: {
        title: 'Custom Overlay',
        description:
          'We display and embed water marks on to the NFT image to reduce scams, this includes Infused traits a chips. All customizable and configurable per contract.',
      },
    },
    partners: {
      title: 'Our Friends',
      description: '--------------',
      ftw: {
        title: 'ForTheWin Network',
        description:
          'A DAO exchange and mint service for beginners, kick start your projects with ease.',
      },
      ttm: {
        title: 'TOTHEMOON Inc',
        description:
          'TOTHEMOON is the largest and most ambitious project on the Neo Chain, featuring arcade games and more..',
      },
      neo: {
        title: 'Neo eco',
        description:
          'A startup community dedicated to be a fair and unbias platform offering trust and pilot style reviews.',
      },
    },
    others: {
      more: 'More',
      help: 'Help',
      grant: 'GrantShares',
      modular: 'Modular by Design',
      open: 'Open Source Battle mechanics',
      paper: 'white Paper',
      contact: 'Contact Me',
      support: 'Support Development',
    },
  },
  formats: {
    number: {
      million: '{n} million | {n} millions',
      billion: '{n} billion | {n} billions',
      trillion: '{n} trillion | {n} trillions',
    },
  },
  auth: {
    login: 'Log In',
    signin: 'Register',
    signout: 'Sign Out',
    walletUser: 'Wallet User',
    creatorUser: 'Creator',
    google: 'Google',
    github: 'Github',
    anonymous: 'Anonymous',
    userNotFound: 'There is no existing user.',
    wrongPassword: 'Wrong Password.',
    invalidEmail: 'The provided Email is invalid.',
    internalError: 'Something goes wrong, please try again;',
    emailAlreadyInUse: 'Email Already In Use',
    confirmEmailPending: 'You need to confirm your email first.',
    resendEmail: 'Resend Confirmation Email',
    resendEmailSuccess: 'Confirmation Email as be resent',
    unauthorizedDomain: 'Unauthorized Domain',
    creatorWarning:
      'Registering as a Creator\nis only required for Contract Owners',
  },
  actions: {
    yes: 'Yes',
    no: 'No',
    configure: 'Configure',
    verify: 'Verify',
  },
  fields: {
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
  },
  validations: {
    compare: '{field} and {other} do not match',
    email: 'The {field} field is not a valid e-mail address',
    required: 'The {field} field is required',
    strength: 'Password is too weak, please improve your strength',
  },
  tabs: {
    global: 'global',
    infuse: 'infuse',
    evolve: 'evolve',
    elements: 'elements',
    nature: 'nature',
    burn: 'burn',
  },
  studio: {
    remove: {
      title: 'Remove Token',
      message: 'Do you sure you wanna to remove that token?',
      success: 'Token successfully removed!',
    },
    contracts: {
      empty: 'There is no contract to show in the connected wallets',
    },
    stats: {
      elements: 'Elements by order',
      background: 'Background',
      water: 'Water',
      wind: 'Wind',
      earth: 'Earth',
      nature: 'Nature',
    },
  },
  elements: {
    standard: 'Standard',
    simple: 'Simple',
    scifi: 'Sci-Fi',
    fantasy: 'Fantasy',
    'grey-4': {
      standard: 'Ignored',
      simple: 'Ignored',
      scifi: 'Ignored',
      fantasy: 'Ignored',
    },
    green: {
      standard: 'Nature',
      simple: 'Plant',
      scifi: 'Biologic',
      fantasy: 'Life',
    },
    yellow: {
      standard: 'Electric',
      simple: 'Shock',
      scifi: 'Plasma',
      fantasy: 'Lighting',
    },
    purple: {
      standard: 'Thunder',
      simple: 'Sound',
      scifi: 'Pulse',
      fantasy: 'Rhythm',
    },
    red: {
      standard: 'Fire',
      simple: 'Flame',
      scifi: 'Thermal',
      fantasy: 'Ember',
    },
    brown: {
      standard: 'Earth',
      simple: 'Ground',
      scifi: 'Neutral',
      fantasy: 'Quake',
    },
    teal: {
      standard: 'Air',
      simple: 'Wind',
      scifi: 'Pressure',
      fantasy: 'Breath',
    },
    blue: {
      standard: 'Water',
      simple: 'Ocean',
      scifi: 'Hydro',
      fantasy: 'Aqua',
    },
    cyan: {
      standard: 'Ice',
      simple: 'Snow',
      scifi: 'Cryo',
      fantasy: 'Frigid',
    },
    'deep-purple': {
      standard: 'Void',
      simple: 'Ghost',
      scifi: 'Gravity',
      fantasy: 'Corrupt',
    },
    white: {
      standard: 'Light',
      simple: 'Day',
      scifi: 'Lumous',
      fantasy: 'Sun',
    },
    'blue-grey': {
      standard: 'Steel',
      simple: 'Metal',
      scifi: 'Plated',
      fantasy: 'Forged',
    },
    black: {
      standard: 'Dark',
      simple: 'Night',
      scifi: 'Necros',
      fantasy: 'Moon',
    },
  },
  natures: {
    type: 'Type',
    mobile: 'Mobility',
    legendary: 'Legendary',
    normal: 'Normal',
    insect: 'Insect',
    poison: 'Poison',
    slime: 'Slime',
    plant: 'Plant',
    beast: 'Beast',
    lizard: 'Lizard',
    warrior: 'Warrior',
    ghost: 'Ghost',
    dragon: 'Dragon',
    fairy: 'Fairy',
    golem: 'Golem',
    digging: 'Digging',
    flying: 'Flying',
    swimming: 'Swimming',
    unknown: 'Unknown',
    divine: 'Divine',
  },
  acceptance: {
    'terms-of-service': 'Terms of Service',
    'privacy-policy': 'Privacy Policy',
  },
  contracts: {
    home: {
      configure: 'Configure my Contracts:',
      create: 'Add new Contract',
      hash: 'Contract Hash',
      name: 'Contract Name',
      noWallet:
        'You need at least one linked wallet in order to verify a contract',
      noHash: 'Contract Hash is required',
      noName: 'Contract Name is required',
    },
    evo: 'Evo Requirement',
    fuse: 'Require Fuse',
    infused: 'Require Infused',
    alias: 'Alias',
    hash: 'Hash',
    owner: 'Owner',
    settings: 'Settings',
  },
  wallet: {
    neoline: 'Neo Line',
    neon: 'Neon Wallet',
    o3: 'O3 Wallet',
    oneGate: 'One Gate Wallet',
    connected: 'Wallets History',
    link: 'Link Wallet',
    available: 'Wallets Available',
    token: {
      invalid: "That token is invalid and/or corrupted, so can't be selected",
      selected: "That token was already be picked, so can't be selected",
    },
    error: {
      disconnect: 'something whong happened while disconnecting',
      connect: 'something whong happened while connecting',
    },
  },
  admin: {
    title: 'Admin Panel',
    stats: 'Stats',
    chains: 'Chains',
    networks: 'Networks',
    contracts: 'Contracts',
    users: 'Users',
    logs: 'Logs',
  },
  logs: {
    chain: 'Chain',
    contract: 'Contract',
    method: 'Method',
    network: 'Network',
    owner: 'Owner',
    time: 'Time',
    wallet: 'Wallet',
  },
  users: {
    email: 'Email',
    displayName: 'Display Name',
    photoUrl: 'Photo',
    disabled: 'Disabled',
    admin: 'Admin',
    ban: 'Ban',
    unban: 'Unban',
  },
  chains: {
    config: 'Config',
    name: 'Name',
    locked: 'Locked',
    networks: 'Networks',
  },
  networks: {
    name: 'Name',
    color: 'Color',
    url: 'Url',
  },
};
