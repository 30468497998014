import {
  GoogleAuthProvider,
  EmailAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithCredential,
  signInAnonymously,
  signOut as _signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification as sendEmailVerification,
  IdTokenResult,
  User,
  GithubAuthProvider,
  AuthProvider,
} from 'firebase/auth';
import { computed, ref, Ref } from 'vue';
import { useFbApp } from 'src/composables/dependecies';
import { useAuth as _useAuth } from '@vueuse/firebase/useAuth';
import { FirebaseApp, FirebaseError } from 'firebase/app';
import { Notify } from 'quasar';
import { useI18n } from 'vue-i18n';

type FbAuth = ReturnType<typeof _useAuth>;
const state: { auth?: FbAuth & { token: Ref<IdTokenResult | undefined> } } = {};

export function useAuth(fbApp: FirebaseApp | undefined = undefined) {
  type Translate = ReturnType<typeof useI18n>['t'];
  let t: Translate = () => '';

  const isSetup = !fbApp;
  if (isSetup) {
    fbApp = useFbApp();
    const i18n = useI18n();
    t = i18n.t;
  }

  const auth = getAuth(fbApp);
  if (!state.auth) {
    state.auth = { ..._useAuth(auth), token: ref<IdTokenResult>() };
  }

  const { user, token, isAuthenticated: _isAuthenticated } = state.auth;
  function notifyError(fbError: FirebaseError) {
    let message = 'auth.internalError';
    switch (fbError.code) {
      case 'auth/wrong-password':
        message = 'auth.wrongPassword';
        break;
      case 'auth/user-not-found':
        message = 'auth.userNotFound';
        break;
      case 'auth/invalid-email':
        message = 'auth.invalidEmail';
        break;
      case 'auth/email-already-in-use':
        message = 'auth.emailAlreadyInUse';
        break;
      case 'auth/unauthorized-domain':
        message = 'auth.unauthorizedDomain';
        break;
    }
    Notify.create({
      message: t(message),
      color: 'negative',
    });
  }

  function createAuthPopupFn(provider: AuthProvider) {
    return async () => {
      try {
        const res = await signInWithPopup(auth, provider);
        return res;
      } catch (err) {
        notifyError(err as FirebaseError);
      }
    };
  }

  const logInGoogle = createAuthPopupFn(new GoogleAuthProvider());
  const logInGithub = createAuthPopupFn(new GithubAuthProvider());
  const logInEmail = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const credentials = EmailAuthProvider.credential(email, password);
    try {
      const res = await signInWithCredential(auth, credentials);
      return res;
    } catch (err) {
      notifyError(err as FirebaseError);
    }
  };
  const logInAnonymous = () => signInAnonymously(auth);
  const signOut = () => _signOut(auth);

  const signInEmail = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      return res;
    } catch (err) {
      notifyError(err as FirebaseError);
    }
  };

  const isAdmin = computed(() => {
    return token.value?.claims.admin;
  });

  const isAuthenticated = computed(() => {
    return (
      _isAuthenticated.value &&
      user.value?.emailVerified &&
      !user.value?.isAnonymous
    );
  });

  async function updateToken(_user?: User | null) {
    token.value = await (_user || user.value)?.getIdTokenResult();
  }
  auth.onAuthStateChanged(updateToken);
  function sendEmail(_user: User | undefined = undefined) {
    return sendEmailVerification(_user || (user.value as User));
  }
  return {
    user,
    token,
    isAdmin,
    isAuthenticated,
    updateToken,
    logInGoogle,
    logInGithub,
    logInEmail,
    logInAnonymous,
    signOut,
    signInEmail,
    sendEmail,
  };
}
