import {
  WalletContext,
  WalletName,
  WalletRef,
  WalletState,
} from 'src/types/wallets';
import { getNeoLineWallet } from 'src/services/wallets/neoline';
import { getNeonWalletConnectWallet } from 'src/services/wallets/neon';
import { getO3Wallet } from 'src/services/wallets/o3';
import { getOneGateWallet } from 'src/services/wallets/oneGate';
import { computed, ref } from 'vue';
import { useNeolineApi } from 'src/composables/dependecies';
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

const walletMap = [
  getNeoLineWallet(),
  getNeonWalletConnectWallet(),
  getO3Wallet(),
  getOneGateWallet(),
].reduce((map, context) => {
  return map.set(context.name, context);
}, new Map<WalletName, WalletContext>());

export const useWalletStore = defineStore('wallet', () => {
  const _wallets: WalletRef[] = [];
  const _states: WalletState[] = [];
  for (const [name, item] of walletMap) {
    _wallets.push({
      name,
      icon: item.wallet.icon,
      address: '',
    });

    _states.push({
      name,
      connected: item.adapter.connected,
    });
  }

  const wallets = useLocalStorage('wallets-list', _wallets);
  const states = ref(_states);

  function isConnected(name: WalletName): boolean | undefined {
    const state = states.value.find((state) => state.name === name);
    return state?.connected;
  }

  function getWallets() {
    return wallets.value.map((wallet) => {
      const connected = isConnected(wallet.name);
      return { ...wallet, connected };
    });
  }

  const walletsAvailable = computed(() =>
    getWallets().filter((wallet) => !wallet.address)
  );
  const walletsAddressed = computed(() =>
    getWallets().filter((wallet) => !!wallet.address)
  );
  const walletsConnected = computed(() =>
    getWallets().filter((wallet) => wallet.connected)
  );

  for (const index in wallets.value) {
    const name = wallets.value[index].name;
    const { adapter } = walletMap.get(name) || {};

    adapter?.addListener('connect', async () => {
      states.value[index].connected = adapter.connected;
      wallets.value[index].address = adapter.address;
    });
    adapter?.addListener('disconnect', () => {
      states.value[index].connected = adapter.connected;
    });
  }

  function getWallet(name: WalletName): Partial<WalletContext> {
    const ctx = walletMap.get(name);
    if (ctx && !ctx.service.api) {
      switch (ctx.name) {
        case 'neoline':
          ctx.service.api = useNeolineApi();
          break;
      }
    }
    return ctx || {};
  }

  function setAddress(name: WalletName, address?: string | null) {
    const index = wallets.value.findIndex((wallet) => wallet.name === name);
    if (index !== -1) {
      wallets.value[index].address = address;
    }
  }

  getWallet('neoline');
  return {
    wallets,
    walletsAvailable,
    walletsAddressed,
    walletsConnected,
    isConnected,
    getWallet,
    setAddress,
  };
});
