import {
  widthMapKey,
  maxWidthKey,
  inlineKey,
} from 'src/composables/dependecies';
import { ref, computed } from 'vue';
import { WidthMap } from 'src/types/form';
import { boot } from 'quasar/wrappers';

export default boot(({ app }) => {
  const widthMap = ref<WidthMap>();
  const maxWidth = ref<number>();
  const inline = ref<boolean>();

  app.provide(widthMapKey, widthMap);
  app.provide(
    maxWidthKey,
    computed(() => maxWidth.value)
  );
  app.provide(
    inlineKey,
    computed(() => inline.value)
  );
});
