import { AxiosInstance } from 'axios';
import { FirebaseApp } from 'firebase/app';
import { WidthMap } from 'src/types/form';
import { ComputedRef, inject, InjectionKey, Ref } from 'vue';
import { Viewer } from '@toast-ui/editor';

export const fbAppKey: InjectionKey<FirebaseApp> = Symbol('firebase-app-key');
export const neolineApiKey: InjectionKey<AxiosInstance> =
  Symbol('neoline-api-key');
export const adminApiKey: InjectionKey<AxiosInstance> = Symbol('admin-api-key');

export const widthMapKey: InjectionKey<Ref<WidthMap>> = Symbol(
  'bh-form-width-map-key'
);
export const maxWidthKey: InjectionKey<ComputedRef<number>> =
  Symbol('bh-form-max-width');
export const inlineKey: InjectionKey<ComputedRef<boolean>> =
  Symbol('bh-form-inline');

export const viewerKey: InjectionKey<Ref<Viewer>> = Symbol('tui-viewer-key');

export function useFbApp() {
  const fbApp = inject(fbAppKey);
  if (!fbApp) {
    throw 'firebase app not injected';
  }
  return fbApp;
}

export function useNeolineApi() {
  const neolineApi = inject(neolineApiKey);
  if (!neolineApi) {
    throw 'neoline api not injected';
  }
  return neolineApi;
}

export function useAdminApi() {
  const adminApi = inject(adminApiKey);
  if (!adminApi) {
    throw 'admin api not injected';
  }
  return adminApi;
}

export function useWidthMap() {
  const widthMap = inject(widthMapKey);
  if (!widthMap) {
    throw 'form width map not injected';
  }
  return widthMap;
}
export function useMaxWidth() {
  const maxWidth = inject(maxWidthKey);
  if (!maxWidth) {
    throw 'form max width not injected';
  }
  return maxWidth;
}
export function useInline() {
  const inline = inject(inlineKey);
  if (!inline) {
    throw 'form inline not injected';
  }
  return inline;
}
export function useViewer() {
  const viewer = inject(viewerKey);
  if (!viewer) {
    throw 'tui markdown viewer not injected';
  }
  return viewer;
}
