import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import {
  adminApiKey,
  fbAppKey,
  neolineApiKey,
} from 'src/composables/dependecies';
import { inject } from 'src/utils/boot';
import { useAuth } from 'src/composables/auth';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    readonly neolineApi: AxiosInstance;
    readonly adminApi: AxiosInstance;
  }
}

export default boot(({ app, store }) => {
  const neolineApi = axios.create({ baseURL: process.env.NEOLINE_API_URL });
  const adminApi = axios.create({ baseURL: process.env.ADMIN_API_URL });
  const fbApp = inject(app, fbAppKey);
  const { token } = useAuth(fbApp);

  adminApi.interceptors.request.use(function (config) {
    if (token.value?.token) {
      config.headers.Authorization = `Bearer ${token.value?.token}`;
    }
    return config;
  });

  app.provide(neolineApiKey, neolineApi);
  app.provide(adminApiKey, adminApi);
  store.use(() => ({ adminApi, neolineApi }));
});
