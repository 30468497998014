import { Ref, watch } from 'vue';

export function useAsync() {
  async function awaitNotNull<T>(ref: Ref<T | null | undefined>): Promise<T> {
    if (ref.value) {
      return ref.value;
    }

    return await new Promise((resolve) => {
      const stop = watch(
        () => ref.value,
        () => {
          if (ref.value) {
            stop();
            resolve(ref.value);
          }
        }
      );
    });
  }
  return {
    awaitNotNull,
  };
}
