import { getNeonWalletConnectWallet as getWallet } from '@rentfuse-labs/neo-wallet-adapter-wallets';
import { discard } from 'src/components/shared';
import {
  WalletContext,
  WalletContract,
  WalletService,
} from 'src/types/wallets';

export function getNeonWalletConnectWallet(): WalletContext {
  const wallet = getWallet({
    network: 'neo3:testnet', // 'neo3:private', 'neo3:mainet'
    options: {},
  });
  const adapter = wallet.adapter();
  /**
   * @todo methods not implement yet
   **/
  const service: WalletService = {
    async connect(this: WalletContext): Promise<void> {
      /**
       * @todo check if neon wallet is installed.
       **/
      await this.adapter.connect();
    },
    async disconnect(this: WalletContext) {
      /**
       * @todo check if neon wallet is installed.
       **/
      await this.adapter.disconnect();
    },
    async getBalances() {
      return [];
    },
    async getContracts() {
      return [];
    },
    async getTokens(contract: WalletContract) {
      discard({ readon: 'not implemented: ', contract });
      return [];
    },
    async getToken(contract: WalletContract, tokenId: string) {
      discard({ readon: 'not implemented: ', contract, tokenId });
      return {
        id: tokenId,
        amount: 0,
        name: '#',
        description: '#',
        url: '#',
      };
    },
  };
  const ctx: WalletContext = {
    name: 'neon',
    wallet,
    adapter,
    service,
  };
  ctx.service.connect = ctx.service.connect.bind(ctx);
  ctx.service.disconnect = ctx.service.disconnect.bind(ctx);
  return ctx;
}
