import { RouteRecordRaw } from 'vue-router';
import { RouteParams } from '@quasar/app-webpack';
import { discard } from 'src/components/shared';

export function useRoutes({ store, ssrContext }: RouteParams) {
  discard({ store, ssrContext });

  const routes: RouteRecordRaw[] = [
    {
      path: '/:lang(en|es|pt)?',
      component: () => import('layouts/MainLayout.vue'),
      children: [
        {
          path: '',
          name: 'landpage',
          components: {
            default: () => import('src/pages/landpage/LandpagePage.vue'),
            banner: () => import('src/pages/landpage/LandpageBannerPage.vue'),
          },
        },
        {
          path: '/confirm-email',
          name: 'confirm-email',
          component: () => import('src/pages/auth/ConfirmEmailPage.vue'),
        },
        {
          path: 'studio/create',
          name: 'studio-create',
          component: () => import('src/pages/studio/StudioUpsertPage.vue'),
        },
        {
          path: 'studio/:id',
          name: 'studio-create',
          component: () => import('src/pages/studio/StudioUpsertPage.vue'),
          props: true,
        },
        {
          path: 'studio',
          name: 'studio',
          component: () => import('src/pages/studio/StudioListPage.vue'),
        },
        {
          path: 'contracts',
          name: 'contracts',
          component: () => import('src/pages/contracts/ContractsHomePage.vue'),
        },
        {
          path: 'acceptance/:terms',
          name: 'acceptance',
          component: () => import('src/pages/AcceptancePage.vue'),
          props: true,
        },
        {
          path: 'contracts/configure/:id',
          name: 'contract-configure',
          components: {
            default: () =>
              import(
                'src/pages/contracts/configure/ContractsConfigurePage.vue'
              ),
            header: () =>
              import(
                'src/pages/contracts/configure/ContractsConfigureHeader.vue'
              ),
          },
        },
        {
          path: 'admin/',
          component: () => import('src/pages/admin/AdminHomePage.vue'),
          children: [
            {
              path: 'stats',
              name: 'admin-stats',
              component: () => import('src/pages/admin/AdminStatsPage.vue'),
            },
            {
              path: 'chains',
              name: 'admin-chains',
              component: () => import('src/pages/admin/AdminChainsPage.vue'),
            },
            {
              path: 'contracts',
              name: 'admin-contracts',
              component: () => import('src/pages/admin/AdminContractsPage.vue'),
            },
            {
              path: 'users',
              name: 'admin-users',
              component: () => import('src/pages/admin/AdminUsersPage.vue'),
            },
            {
              path: 'logs',
              name: 'admin-logs',
              component: () => import('src/pages/admin/AdminLogsPage.vue'),
            },
          ],
        },
      ],
    },

    // Always leave this as last one,
    // but you can also remove it
    {
      path: '/:catchAll(.*)*',
      component: () => import('pages/ErrorNotFound.vue'),
    },
  ];

  return routes;
}
