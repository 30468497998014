import { boot } from 'quasar/wrappers';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { useAuth } from 'src/composables/auth';
import { fbAppKey } from 'src/composables/dependecies';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    readonly fbApp: FirebaseApp;
  }
}

export default boot(async ({ app, store }) => {
  const fbApp = initializeApp(process.env.FIREBASE_CONFIG as FirebaseOptions);

  app.provide(fbAppKey, fbApp);
  store.use(() => ({ fbApp }));

  const auth = useAuth(fbApp);
  await auth.updateToken();
});
