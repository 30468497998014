import { App, InjectionKey } from 'vue';

export function inject<T>(app: App, key: InjectionKey<T>): T {
  const _key = key as symbol;
  const dependecy = app._context.provides[_key];
  if (!dependecy) {
    throw `${key} is not injected`;
  }
  return dependecy;
}
