import { getNeoLineWallet as getWallet } from '@rentfuse-labs/neo-wallet-adapter-wallets';
import { AxiosInstance } from 'axios';

import {
  WalletBalance,
  WalletContext,
  WalletContract,
  WalletService,
  WalletToken,
  WalletTokenMeta,
} from '../../types/wallets';

interface StackString {
  type: 'ByteString';
  value: string;
}

interface StackMapItem {
  key: StackString;
  value: StackString | StackMap;
}

interface StackMap {
  type: 'Map';
  value: StackMapItem[];
}

interface StackToken {
  name: string;
  description: string;
  image: string;
  owner: string;
  attributes: {
    level: string;
    type?: string;
  };
}

function mapStack(stack: StackMap): StackToken {
  const record: Record<string, unknown> = {};
  for (const item of stack.value) {
    const key = atob(item.key.value);
    if (item.value.type === 'Map') {
      record[key] = mapStack(item.value);
    } else {
      record[key] = atob(item.value.value);
    }
  }
  return record as unknown as StackToken;
}

declare global {
  interface Window {
    NEOLineN3: unknown;
  }
}

export function getNeoLineWallet(): WalletContext {
  const wallet = getWallet();
  const adapter = wallet.adapter();

  async function doRequest({
    api,
    method,
    params,
  }: {
    api: AxiosInstance;
    method: string;
    params: unknown[];
  }) {
    const req = {
      jsonrpc: '2.0',
      method: method,
      params: params,
      id: 1,
    };
    const { data } = await api.post(
      'https://t5node.tothemoonuniverse.com',
      req
    );
    return data;
  }

  interface Neoline11Balance {
    assethash: string;
    name: string;
    symbol: string;
    tokens: {
      tokenid: string;
      amount: string;
    }[];
  }

  interface Neoline17Balance {
    assethash: string;
    name: string;
    symbol: string;
    amount: string;
    decimals: string;
  }

  function toBase64Id(id: string): string {
    const hash = id
      .match(/[0-9A-F]{2}/g)
      ?.map((id) => parseInt(id) - 30)
      .join('');
    return btoa(hash || '');
  }

  const service: WalletService = {
    async connect(this: WalletContext): Promise<void> {
      if (!process.env.SERVER) {
        if (!window.NEOLineN3) {
          throw Error('neoline 3 is not installed!');
        }
        await this.adapter.connect();
      }
    },
    async disconnect(this: WalletContext) {
      if (!process.env.SERVER) {
        if (!window.NEOLineN3) {
          throw Error('neoline 3 is not installed!');
        }
        await this.adapter.disconnect();
      }
    },
    async getBalances(this: WalletContext, refresh?: boolean) {
      if (!refresh && this.balances?.length) {
        return this.balances;
      }
      if (this.service.api && this.adapter.address) {
        const { result: result17 } = await doRequest({
          api: this.service.api,
          method: 'getnep17balances',
          params: [this.adapter.address],
        });
        this.balances = (result17.balance as Neoline17Balance[]).map(
          (item) =>
            ({
              wallet: this.name,
              hash: item.assethash,
              name: item.name,
              symbol: item.symbol,
              amount: parseFloat(item.amount),
              decimals: parseFloat(item.decimals),
            } as WalletBalance)
        );
      }
      return this.balances || [];
    },
    async getContracts(this: WalletContext, refresh?: boolean) {
      if (!refresh && this.contracts?.length) {
        return this.contracts;
      }
      if (this.service.api && this.adapter.address) {
        const { result: result11 } = await doRequest({
          api: this.service.api,
          method: 'getnep11balances',
          params: [this.adapter.address],
        });

        const { result: result17 } = await doRequest({
          api: this.service.api,
          method: 'getnep17balances',
          params: [this.adapter.address],
        });

        this.contracts = (result11.balance as Neoline11Balance[]).map(
          (item) =>
            ({
              wallet: this.name,
              hash: item.assethash,
              name: item.name,
              symbol: item.symbol,
              tokensMeta: item.tokens.map(
                (t) =>
                  ({
                    id: t.tokenid,
                    amount: parseFloat(t.amount),
                  } as WalletTokenMeta)
              ),
            } as WalletContract)
        );

        this.balances = (result17.balance as Neoline17Balance[]).map(
          (item) =>
            ({
              wallet: this.name,
              hash: item.assethash,
              name: item.name,
              symbol: item.symbol,
              amount: parseFloat(item.amount),
              decimals: parseFloat(item.decimals),
            } as WalletBalance)
        );
        console.log(this.balances);
      }
      return this.contracts || [];
    },
    async getTokens(
      this: WalletContext,
      contract: WalletContract,
      refresh?: boolean
    ) {
      if (
        !refresh &&
        contract.tokens?.length &&
        contract.tokens.length === contract.tokensMeta.length
      ) {
        return contract.tokens;
      }
      if (this.service.api) {
        const tasks: Promise<WalletToken>[] = [];
        for (const token of contract.tokensMeta) {
          const task = this.service.getToken(contract, token.id, refresh);
          tasks.push(task);
        }
        await Promise.all(tasks);
      }
      return contract.tokens || [];
    },
    async getToken(
      this: WalletContext,
      contract: WalletContract,
      tokenId: string,
      refresh?: boolean
    ) {
      const meta = contract.tokensMeta.find((c) => c.id === tokenId);
      if (!meta) {
        throw Error('token meta not found');
      }
      let token = contract.tokens?.find((c) => c.id == meta.id);
      if (!refresh && token) {
        return token;
      }
      if (!this.service.api) {
        throw Error('api not defined');
      }

      const base64Id = toBase64Id(meta.id);
      const { result } = (await doRequest({
        api: this.service.api,
        method: 'invokefunction',
        params: [
          contract.hash,
          'properties',
          [
            {
              type: 'ByteArray',
              value: base64Id,
            },
          ],
        ],
      })) as { result: { stack: StackMap[] } };
      const resToken = mapStack(result.stack[0]);

      token = {
        id: meta.id,
        amount: meta.amount,
        name: resToken.name,
        description: resToken.description,
        url: resToken.image.startsWith('http') ? resToken.image : undefined,
        level: resToken.attributes.level,
        color: resToken.attributes.type,
      };

      if (!contract.tokens) {
        contract.tokens = [];
      }
      contract.tokens.push(token);
      return token;
    },
  };

  const ctx: WalletContext = {
    name: 'neoline',
    wallet,
    adapter,
    service,
  };
  ctx.service.connect = ctx.service.connect.bind(ctx);
  ctx.service.disconnect = ctx.service.disconnect.bind(ctx);
  ctx.service.getBalances = ctx.service.getBalances.bind(ctx);
  ctx.service.getContracts = ctx.service.getContracts.bind(ctx);
  ctx.service.getTokens = ctx.service.getTokens.bind(ctx);
  ctx.service.getToken = ctx.service.getToken.bind(ctx);
  return ctx;
}
