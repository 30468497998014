import { ref, computed, ExtractPropTypes, SetupContext } from 'vue';

export function discard(ctx: unknown) {
  return ctx;
}

export const sharedInputProps = {
  filled: {
    type: Boolean,
    default: true,
  },
};
type SharedInputProps = typeof sharedInputProps;
export function useMySharedLogic({
  props,
  attrs,
  slots,
}: {
  props: ExtractPropTypes<SharedInputProps>;
  attrs: SetupContext['attrs'];
  slots: SetupContext['slots'];
}) {
  /* shared logic, refs, computed, methods, etc */
  discard({ filled: props.filled, attrs, slots });
  const myState = ref('');
  const myGetter = computed(() => `computed: ${myState.value}`);
  async function myAction() {
    // await doYourMagic()
  }
  return {
    myState,
    myGetter,
    myAction,
  };
}
